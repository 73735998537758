import React from "react"
import Layout from "../components/layout"
import Seo from '../components/seo'

function openCookieSettings(){
  if (window.OneTrust != null) {
    window.OneTrust.ToggleInfoDisplay();
  }
}

export default function PrivacyPolicy() {

  return <Layout>
    <Seo title="Privacy policy" />
    <div className="container">
      <div className="basic-page-content">

        <h2>Privacy Policy</h2>

        <p className="small">Last updated: 7 December 2022</p>

        <p>This is the privacy policy of Silta Finance AG (“Silta”, “we” or “us”), a Swiss limited company (reg. no. CHE-162.320.632). For more information about Silta, see our website: <a href="https://silta.finance">silta.finance</a>.</p>

        <hr />
        <h2>What is this policy?</h2>

        <p>We respect your privacy and your control over your own data. In this policy, we explain what kind of personal data we collect from people or entities who sign up, create a user account, or use the Silta platform in other ways. We also explain why we collect such information, how we use it, how we keep it secure, and who we share it with, and how you can exercise your rights with regard to your personal data.</p>

        <p>This policy is made and interpreted in accordance with the EU General Data Protection Regulation (2016/679). The application and interpretation of this policy in accordance with the laws of an user’s jurisdiction may be accepted by us at our discretion provided that such application and interpretation is not in conflict with the EU General Data Protection Regulation (2016/679).</p>

        <p>We regularly review this policy and may change it from time to time. Please review this policy regularly to keep informed about our practices. By using the Silta platform, our website or services, you agree to this policy as may be amended from time to time and are bound by it.</p>

        <p>If you have any questions about this policy or if you would like to exercise your rights to your personal data described further below, contact us by email at <a href="mailto:privacy@silta.finance">privacy@silta.finance</a>.</p>

        <hr />
        <h2>Age restrictions</h2>

        <p>In order to protect the privacy of minors, the minimum age for signing up on the Silta platform or using any of Silta services is 18 years. Regardless of your age, you cannot sign up or use any of Silta services if you are considered to be a minor in your country.</p>


        <hr />
        <h2>What is personal data?</h2>

        <p>Personal data means any data that allows the identification of a person directly or indirectly. For example, combining different kinds of data may enable identifying a person even when each data separately would not allow that. Personal data can include many types of information, such as a name, location data, an online identifier, IP address or the like.</p>

        <p>As to what personal data is being processed, we will ask you for your email address and full name when you sign up on the Silta newsletter or for any other Silta service. We may ask for proof of identity in some cases.</p>

        <p>We may also collect and process information concerning your use of the Silta platform or your browsing behavior on our website, your IP address, and other statistical information through the use of cookies on our website or on the platform. See the “Cookie Policy” section below for more information.</p>


        <hr />
        <h2>Processing personal data</h2>

        <p>We may process your personal data to do for the following purposes: </p>

        <ul>
          <li>Provide you with a user account on the Silta platform and allow you to authenticate yourself in order to access the platform; </li>
          <li>Seek your comments, feedback and suggestions on the functionality of the Silta platform; </li>
          <li>Notify you of changes, upgrades or problems in the functionality of the Silta platform; </li>
          <li>Send you communications which you have requested and which may be of interest to you.</li>
        </ul>

        <p>
          We will update this privacy policy (including the purposes of processing personal data) as and when new services are being offered on the Silta platform.
        </p>
        <p>
        We may also process your personal data based on our legitimate interests. This means that we have a legitimate interest to process your personal data for example based on our relationship with you as a user, there is a limited privacy impact on you, and you should reasonably expect your data to be used that way.
        </p>
        <p>
        Silta has a legitimate interest, for example, to process your personal data to develop its platform and its business, based on the contractual relationship between you and Silta. Further, we have a legitimate interest to communicate with our users about products or services that may be available through the Silta platform, unless they have declined such communications.
        </p>
        <p>
        We may also process your personal data for specific purposes based on your consent, if we have separately requested and obtained your consent.
        </p>
        <p>
        Processing of your personal data may also become necessary for the performance of any contractual relationship later created by mutual agreement between Silta and you.
        </p>

        <hr />
        <h2>Data protection</h2>

        <p>In order to protect your personal data, any personal data we ask from you is entered by you on secure web pages. A lock icon will appear in a web browser to indicate that a web page is secure. </p>

        <p>Once we receive your data, we take steps to ensure that it&rsquo;s treated securely. Personal data is stored on servers and computers protected by usernames and passwords, firewalls and asymmetric cryptography keys in line with the best practice and industry standards. Passwords are stored in encrypted form. </p>

        <p>Keep in mind that your user account on the Silta platform can be accessed by anyone who knows your username and password. We enforce a certain standard of passwords, but it is your own responsibility to choose a password which is difficult to guess, to keep it confidential, and to change it from time to time. </p>

        <hr />
        <h2>Sharing personal data</h2>

        <p>We will not sell or rent your personal data to third parties, unless we are required to do so by law, a court order or similar judgement, or unless we obtain your prior consent.</p>

        <p>We use certain third-party mailing, survey and chat services for which we may share your user name and email address. Most typically we use common services like MailChimp.</p>

        <p>We may also use third party subcontractors (e.g. hosting services) in providing our services, in which context they may also process your personal data.</p>

        <hr />
        <h2>Data storage</h2>

        <p>By using the Silta platform, you consent to our storing your personal data on our servers. We will retain such data for as long as it is relevant for the purpose for which the data was collected, unless you withdraw your consent before that. </p>

        <p>You may request to close your account on the Silta platform at any time. After we receive your request to close the account, we will do so without delay, as long as you have no outstanding obligations or a contractual relationship with us. We will delete all your personal data when your account is closed, unless there are applicable laws or statutes which obligate or allow us to retain your personal data longer. </p>

        <hr />
        <h2>Your choices and consent</h2>

        <p>You decide whether and how much information you wish to receive from us. If you do not wish to receive further communications from us, you can click on the “unsubscribe” link in any email communications received from us, or reach out to us by email at <a href="mailto:privacy@silta.finance">privacy@silta.finance</a> and let us know that you wish to unsubscribe from the information we provide.</p>

        <p>We may notify you of changes or problems in the Silta platform functionality, and we may contact you in respect of your obligations or contractual relationship with us irrespective of your privacy choices. However, we will not contact you for feedback or for marketing purposes, (i) unless we have a legitimate interest thereto, (ii) if we do not have your valid consent or (iii) if you have refused such marketing. </p>

        <hr />
        <h2>Accessing and updating personal data </h2>

        <p>You can view, update and correct your personal data in your user account settings on the Silta platform. You can also communicate with us by email at <a href="mailto:privacy@silta.finance">privacy@silta.finance</a>. </p>

        <hr />
        <h2 id="cookies">Cookie policy</h2>

        <p>'Cookies' are small pieces of information sent by the website to your computer and stored on your hard drive. They are commonly used to make websites work better and to allow websites to recognise you during your visits. </p>

        <p>The Silta website and platform uses cookies and integrates with Google Analytics to provide additional analysis of user behavior on the website and platform. This information makes it possible for you to sign onto our platform and allows us to deliver a better and more personalised user experience for you. The information collected using cookies on our platform includes, in particular, your browsing behavior on our website, your IP address, and other statistical information such as which pages on the website are accessed by you and when. </p>
        <p>In particular Google Analytics uses cookies for: </p>
        <ul>
          <li>remarketing.</li>
          <li>impression reporting on the Google Display Network.</li>
          <li>demographics and interest reporting in Google Analytics.</li>
          <li>integrated services that rely on Google Analytics to acquire data for advertising purposes, such as data collected via advertising cookies and IDs.</li>
        </ul>
        
        <p>It is possible to switch off cookies in your browser preferences. Turning off cookies may result in a loss of functionality when using our website or Silta platform. To learn more about cookies, visit <a href="http://allaboutcookies.org/" target="_blank" rel="noreferrer">allaboutcookies.org</a>.</p>

        <p>You can manage your cookie settings via the link below.</p>

        <p><button onClick={openCookieSettings} className="btn btn-sm btn-primary ot-sdk-show-settings">Cookie settings</button></p>

        <hr />
        <h2>Links to other websites </h2>

        <p>Our website may contain links to websites run by other companies, organisations, or individuals. We are not responsible for the privacy policies or data collection practices of third parties, their websites or other sites which you find using such links. </p>

        <hr />
        <h2>Your rights</h2>

        <p>You have various rights with regard to your personal data. However, particular rights may be subject to qualifications or specific requirements arising from applicable laws and directives that are not fully described here. </p>

        <ul>
          <li><strong>Right to access your personal data:</strong> You have the right to get confirmation from us on whether your personal data is being processed. You also have the right to access your personal data and to obtain certain information, such as the purposes of processing your personal data and the types of personal data involved. </li>
          <li><strong>Right to rectification:</strong> You have the right to request us to correct inaccurate personal data about you. </li>
          <li><strong>Right to erasure:</strong> You have the right to request us to erase personal data about you in certain situations, such as if the data is no longer necessary for the purposes for which it was collected, if processing such data is unlawful or if the data was processed based on consent and you withdraw your consent. </li>
          <li><strong>Right to restriction of processing your personal data:</strong> You have the right to restrict processing of your personal data in certain situations. If you exercise this right, we are generally only allowed to store the data in question. You might exercise this right, for example, if you believe that the personal data we hold about you is inaccurate, in which case your right to restriction of processing would apply for the time that it takes us to verify whether your belief is correct.</li>
          <li><strong>Right to object to processing your personal data:</strong> You have at any time the right to object to processing your personal data for direct marketing purposes. You are also entitled to, on the grounds of your particular situation, object to processing of your personal data that we do based on our legitimate interest. </li>
          <li><strong>Right to data portability:</strong> You have the right to obtain your personal data in a structured, commonly used and machine-readable format and to transfer the data to another controller, if we process your personal data based on your consent or because of a contract that we have entered with you. For this right to be available to you, our processing of your personal data must take place by automated means. </li>
          <li><strong>Right to withdraw your consent:</strong> To the extent we process your personal data based on your consent, you have the right to withdraw your consent at any time. You can do so by sending an email to <a href="mailto:privacy@silta.finance">privacy@silta.finance</a>. You can also manage your consent preferences by ticking the relevant boxes in the privacy section of your user account settings on the Silta platform. Withdrawing your consent does not affect the lawfulness of processing of your personal data based on consent before its withdrawal. </li>
          <li><strong>Right to make a complaint with the data protection authorities:</strong> You have the right to make a complaint with the data protection authorities concerning our processing of your personal data. You can make a complaint with the data protection authority of the EU member state where you have your permanent residence or where you work, or where you find that a potential breach with regard to your personal data has occurred. </li>
        </ul>
      </div>
    </div>
  </Layout>
}
